
@import url("https://use.typekit.net/mls4kch.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    background: #fff8f2;
    color: #4a2f1f;
}

nav{
    font-family: "soprani-variable", sans-serif;
}

h1 {
    font-family: "soprani-variable", sans-serif;
}

.p-first-line-indent {
  text-indent: 2em;
}

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }


